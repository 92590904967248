import EmptyTypesService from "./EmptyTypesService";

let memberData: Member[] = [];

let currentMember: Member = EmptyTypesService.emptyMember();

const setMemberData = (data: Member[]) => {
    if (currentMember.id === "") {
        setCurrentMember(data[0])
    }
    memberData = data;
}

const getMemberData = () => {
    return memberData;
}

const setCurrentMember = (data: Member) => {
    currentMember = data;
}

const getCurrentMember = (): Member  => {
    return currentMember;
}

const getUrlAddition = (multiple:boolean = false) => {
    if (currentMember.id === "") {
        return "";
    } else {
        if (multiple) {
            return `&memberId=${currentMember.id}`
        } else {
            return `?memberId=${currentMember.id}`
        }
    }
}

const MemberService = {
    setMemberData,
    getMemberData,
    setCurrentMember,
    getCurrentMember,
    getUrlAddition
};

export default MemberService;