import { Help, Delete } from "@mui/icons-material";
import { Typography, FormHelperText, List, ListItem, IconButton, ListItemText, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button } from "@mui/material";
import EnumToStringService from "../../services/EnumToStringService";
import { useState } from "react";


interface EditBankAccountTypesCompProps {
    isMobile: boolean
    config: Config
    setBankAccountTypes: Function
    bankAccountTypes: string[]
    bankAccountTypeToAdd: string
    setBankAccountTypeToAdd: Function
}

export const EditBankAccountTypesComp: React.FC<EditBankAccountTypesCompProps> = (props) => {
    const [showDefaultAccountHelpText, setShowDefaultAccountHelpText] = useState<boolean>(false);
    const [showLoanAccountHelpText, setShowLoanAccountHelpText] = useState<boolean>(false);
    const style = {
        marginTop: "10px",
    }
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false)

    return (
        <div>
            <Typography style={style} variant="body1">In Verwendung für</Typography>
            {showDefaultAccountHelpText && <FormHelperText>Um Ihr Standardkonto zu ändern, wählen Sie beim gewünschten Konto die Option "Standardkonto" als neue Verwendung aus und Speichern Sie.</FormHelperText>}
            {showLoanAccountHelpText && <FormHelperText>Sie haben dieses Konto speziell ausgewählt für die aufgeführten Darlehen. Diese Zuweisung kann aktuell leider noch nicht über das Mitgliederportal geändert werden.</FormHelperText>}
            <List>
                {//@ts-ignore
                    props.bankAccountTypes.map(typeString => (
                        <ListItem key={typeString}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                    if (typeString === "DEFAULT") {
                                        setShowDefaultAccountHelpText(!showDefaultAccountHelpText);
                                    } else if (EnumToStringService.getBankAccountType(typeString).startsWith("Darlehen ")) {
                                        setShowLoanAccountHelpText(!showLoanAccountHelpText);
                                    } else {
                                        props.setBankAccountTypes(props.bankAccountTypes.filter(e => e !== typeString));
                                    }
                                }}>
                                    {typeString === "DEFAULT" || EnumToStringService.getBankAccountType(typeString).startsWith("Darlehen ") ? <Help /> : <Delete />}
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={`${EnumToStringService.getBankAccountType(typeString)}`}
                            />
                        </ListItem>
                    ))}

            </List>

            <FormControl style={{ ...style, width: "calc(100% - 127px)" }} fullWidth>
                <InputLabel size='small'>Neue Verwendung auswählen</InputLabel>
                <Select
                    label="Neue Verwendung auswählen"
                    name='bankaccounttype'
                    variant="outlined"
                    size="small"
                    //Not compatible with handlechange() as it's a different event
                    onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
                        props.setBankAccountTypeToAdd(e.target.value as string);
                        reload(!reloadVar);
                    }}
                    value={props.bankAccountTypeToAdd as ""}
                    required
                >
                    {props.bankAccountTypes.filter(s => s === "DEFAULT").length === 0 && <MenuItem value={"DEFAULT"}>Standardkonto</MenuItem>}
                    {props.bankAccountTypes.filter(s => s === "SHARE_INTEREST").length === 0 && <MenuItem value={"SHARE_INTEREST"}>Zinsen von Anteilen</MenuItem>}
                    {props.bankAccountTypes.filter(s => s === "SHARE_COLLECT").length === 0 && <MenuItem value={"SHARE_COLLECT"}>Einzug von Anteilen</MenuItem>}
                    {props.bankAccountTypes.filter(s => s === "LOAN_INTEREST").length === 0 && <MenuItem value={"LOAN_INTEREST"}>Zinsen von Darlehen</MenuItem>}
                    {props.bankAccountTypes.filter(s => s === "LOAN_COLLECT").length === 0 && <MenuItem value={"LOAN_COLLECT"}>Einzug von Darlehen</MenuItem>}
                    {props.bankAccountTypes.filter(s => s === "LOAN_REPAYMENTS").length === 0 && <MenuItem value={"LOAN_REPAYMENTS"}>Darlehenstilgungen</MenuItem>}
                </Select>
            </FormControl>
            <Button style={{ ...style, width: "fit-content", float: "right" }} variant="contained" onClick={() => {
                props.setBankAccountTypes([...props.bankAccountTypes, props.bankAccountTypeToAdd]);
                props.setBankAccountTypeToAdd("");
            }} disabled={props.bankAccountTypeToAdd === "" ? true : false}>Hinzufügen</Button>
        </div>
    )
}
