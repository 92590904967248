import { Typography } from "@mui/material"
import AuthService from "../../services/AuthService";
import { useEffect } from "react";


interface ErrorProps {
    config: Config
}

export const NotMemberError: React.FC<ErrorProps> = (props) => {

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        }
    }, []);

    return (
        <div className="content">
            <Typography variant="h6">Mitgliederportal</Typography>
            <Typography variant="body1">Sie scheinen aktuell kein Mitglied der {props.config.cooperativeName} zu sein. Wenn sie glauben, dass es sich dabei um einen Fehler handelt, kontaktieren sie uns bitte.</Typography>
        </div>
    )
}
