import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"


export const Error: React.FC = () => {
    let {status} = useParams();
    return (
        <div className="content">
            <Typography variant="h6">Mitgliederportal</Typography>
            <Typography variant="body1">Fehler {status ? status: "404"}</Typography>
            {status ? <>
                {status === "406" ? <>
                    <Typography variant="body1">Ihre Anfrage an den Server konnte nicht akzeptiert werden.</Typography>
                    <Typography variant="body2">Die Gründe dafür hängen von der Art der Anfrage ab:</Typography>
                    <Typography variant="body2">Wenn Sie eine Zeichnungsabsicht abgeschickt haben, könnte der Fehler aufgetreten sein, weil entweder ihr(e) Vorname, Nachname, 
                        Anrede, Adresse, Bankverbindung oder Darlehenssumme nicht hinterlegt ist oder kein Projekt ausgewählt war.
                    </Typography>
                    <Typography variant="body2">Generell kann auch eine fehlerhafte Form der Anfrage ein Grund sein.</Typography>
                </> : <></>}
                {status === "409" ? <>
                    <Typography variant="body1">Konflikt/Dopplung</Typography>
                    <Typography variant="body2">Die Gründe dafür hängen von der Art der Anfrage ab:</Typography>
                    <Typography variant="body2">Wenn Sie versucht haben eine neue Bankverbindung hinzuzufügen, überprüfen Sie bitte, ob Sie nicht bereits eine Bankverbindung mit der selben IBAN hinzugefügt haben.
                    </Typography>
                </> : <></>}
            </> : <></>}
        </div>
    )
}
