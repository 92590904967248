import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EmptyTypesService from '../../services/EmptyTypesService';
import AuthService from '../../services/AuthService';
import { validationService } from '../../services/ValidationService';
import { LoadingButton } from '@mui/lab';
import { Typography, TextField, Checkbox, FormControlLabel, FormHelperText, Box } from '@mui/material';
import { EditBankAccountTypesComp } from './EditBankAccountTypesComp';
import axios from 'axios';
import reCAPTCHA from '../../services/CaptchaService';
import InputValue from '../../classes/InputValue';
import { CheckBox } from '@mui/icons-material';
import DataService from '../../services/DataService';
import MemberService from '../../services/MemberService';

interface CreateBankAccountProps {
    isMobile: boolean;
    config: Config;
}

export const CreateBankAccount: React.FC<CreateBankAccountProps> = (props) => {
    const navigate = useNavigate();
    //boolean if site is currently doing validations => shows "Speichern"-Button if it should show a loading circle
    const [saveBtnIsLoading, setSaveBtnIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [values, setValues] = useState<any>({
        iban: new InputValue('', false),
        name: new InputValue('', false),
        bankName: new InputValue('', false),
        bankBic: new InputValue('', false),
        isDebit: new InputValue('', false),
    });

    const [bankAccountTypes, setBankAccountTypes] = useState<string[]>([]);
    const [bankAccountTypeToAdd, setBankAccountTypeToAdd] = useState<string>('');

    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            const script = document.createElement('script');
            script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.6Lf8gPkpAAAAAM6RBzLxIGc2zb-ayqkEfXG2bA25}`;
            document.body.appendChild(script);
        }
    }, []);
    const style = {
        marginTop: '10px',
    };

    //Save request
    const saveBankAccount = async (setSaveBtnIsLoading: Function) => {
        DataService.performAsyncPostRequest(`/bankaccount${MemberService.getUrlAddition()}`, navigate,
            {
                iban: values.iban.getValue(),
                name: values.name.getValue(),
                bank: {
                    name: values.bankName.getValue(),
                    bic: values.bankBic.getValue(),
                },
                isDebit: true,
                isActive: true,
                bankAccountTypes: bankAccountTypes,
            },
        ).then(() => {
            setSaveBtnIsLoading(false);
            navigate('/personaldata');
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checkFor: string) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        values[name].setValue(value);
        //in this step required can be false (even if its true) because required is checked on submit, not on change
        validationService.validateFunction(values, name, checkFor, false, false, props.config);
        reload(!reloadVar);
    };


    return (
        <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            padding={2}
        ><Box
            className='tableDiv'
            width={props.isMobile ? '100%' : '50%'}
            display='inline-block'
        >
            <Typography variant='h6'>Neues Konto hinzufügen</Typography>
            <TextField style={style} fullWidth value={values.iban.getValue()} error={values.iban.getError()}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'iban')}
                       onBlur={() => validationService.validateIbanFromBackend(values, 'iban', 'bankBic', 'bankName', props.config, () => {
                           reload(!reloadVar);
                       })}
                       name='iban' label='IBAN' variant='outlined' size='small' required />
            <TextField style={style} fullWidth value={values.name.getValue()} error={values.name.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='name' label='Kontoinhaber'
                       variant='outlined' size='small' required />
            <TextField style={style} fullWidth value={values.bankBic.getValue()} error={values.bankBic.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'bic')} name='bankBic' label='BIC'
                       variant='outlined' size='small' required />
            <TextField style={style} fullWidth value={values.bankName.getValue()} error={values.bankName.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='bankName'
                       label='Kreditinstitut' variant='outlined' size='small' required />
            {values.isDebit && <Typography variant='body2' style={style}>
                Mit einem Klick auf "Speichern" erteile ich der {props.config.bank_owner} ein SEPA-Basismandat und gestatte widerruflich mögliche Beträge von
                genanntem Konto einzuziehen. Die Gläubiger-Identifikationsnummer ist {props.config.creditorIdentificationNumber}.
            </Typography>}
            <FormHelperText>Um die Verwendungsmöglichkeit Ihres Kontos nicht eingrezen zu müssen, benötigen wir eine Einzugsermächtigung. Etwas abgebucht wird aber nur, wenn Sie das Konto unter "In Verwendung für" als Standardkonto oder
                Einzugskonto auswählen.</FormHelperText>

            <EditBankAccountTypesComp isMobile={props.isMobile} config={props.config}
                                      setBankAccountTypeToAdd={setBankAccountTypeToAdd}
                                      setBankAccountTypes={setBankAccountTypes}
                                      bankAccountTypeToAdd={bankAccountTypeToAdd}
                                      bankAccountTypes={bankAccountTypes} />

            <div style={{ textAlign: 'right' }}>
                {isError && <Typography variant='caption' color={'#d32f2f'}>Bitte überprüfen Sie ihre Eingabe und speichern erneut</Typography>}
            </div>
            <div style={{ textAlign: 'right' }}>
                <LoadingButton loading={saveBtnIsLoading} variant='contained' disableElevation sx={{ marginTop: '10px' }} onClick={async () => {
                    setSaveBtnIsLoading(true);
                    let isError = false;
                    //name,checkFor,isNextStep,isRequired
                    if (!validationService.validateFunction(values, 'iban', 'iban', true, true, props.config)) isError = true;
                    await new Promise(resolve => validationService.validateIbanFromBackend(values, 'iban', 'bankBic', 'bankName', props.config, resolve));
                    if (values.iban.getError()) isError = true;
                    if (!validationService.validateFunction(values, 'name', 'alphabet', true, true, props.config)) isError = true;
                    if (!validationService.validateFunction(values, 'bankBic', 'bic', true, true, props.config)) isError = true;
                    if (!validationService.validateFunction(values, 'bankName', 'alphabet', true, true, props.config)) isError = true;
                    reload(!reloadVar);
                    if (!isError) {
                        setIsError(false);
                        saveBankAccount(setSaveBtnIsLoading);
                    } else {
                        setIsError(true);
                        setSaveBtnIsLoading(false);
                    }
                }}>Speichern</LoadingButton>

            </div>

        </Box>
        </Box>
    );
};
