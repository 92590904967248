import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import InputValue from '../classes/InputValue';
import DataService from '../services/DataService';
import MemberService from '../services/MemberService';
import EmptyTypesService from '../services/EmptyTypesService';
import { LoadingButton } from '@mui/lab';
import { validationService } from '../services/ValidationService';
import { Loading } from '../components/Loading';
import EnumToStringService from '../services/EnumToStringService';

interface NewLoanProps {
    isMobile: boolean;
    config: Config;
}

declare global {
    type PaymentOption = {
        id: string,
        code: string,
        name: string,
        activated: boolean
    }
}

export const NewLoan: React.FC<NewLoanProps> = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [finishedLoading, setFinishedLoading] = useState<{
        nLoanMember: boolean,
        nLoanProject: boolean,
        paymentOptions: boolean,
        nLoanBankAccounts: boolean
    }>({ nLoanMember: false, nLoanProject: false, paymentOptions: false, nLoanBankAccounts: false });
    const [memberData, setMemberData] = useState<Member>(EmptyTypesService.emptyMember());
    const [projectData, setProjectData] = useState<Project[]>([EmptyTypesService.emptyProject()]);
    //Saves list of payment options from request
    const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
    const [bankData, setBankData] = useState<Bank_Account[]>([]);

    //Var if button should show "Loading"
    const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false);

    const isOrganisation = () => {
        return memberData.type !== 'beg_Contacts';
    };
    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/member/info${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setMemberData, 'nLoanMember', navigate);
            DataService.performRequest(`/projects?id=${id}`, finishedLoading, setFinishedLoading, setProjectData, 'nLoanProject', navigate);
            DataService.performAnonymousRequest(`/rest/entities/beg_PaymentOption`, finishedLoading, setFinishedLoading, setPaymentOptions, 'paymentOptions', navigate);
            updateBankAccounts();
        }
    }, []);
    const updateBankAccounts = () => {
        DataService.performRequest(`/bankaccount${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setBankData, 'nLoanBankAccounts', navigate)
            .then((response) => {
                //@ts-ignore
                const rawObject: Bank_Account[] = response.data;
                let defaultBA: Bank_Account = EmptyTypesService.emptyBankAccount();
                let loanCollectBA: Bank_Account = EmptyTypesService.emptyBankAccount();
                rawObject.map((bankAccount: Bank_Account) => {
                    if (bankAccount.bankAccountTypes.includes('LOAN_COLLECT')) {
                        loanCollectBA = bankAccount;
                    }
                    if (bankAccount.bankAccountTypes.includes('DEFAULT')) {
                        defaultBA = bankAccount;
                    }
                });
                if (loanCollectBA.id === '' && defaultBA.id !== '') {
                    values.bankAccountId.setValue(defaultBA.id);
                    values.bankAccountId.setInfo('SET');
                } else if (loanCollectBA.id !== '') {
                    values.bankAccountId.setValue(loanCollectBA.id);
                    values.bankAccountId.setInfo('SET');
                }
            });
    };

    const [values] = useState<any>({
        loanSum: new InputValue(props.config.loanMinSum, false),
        paymentType: new InputValue('', false),
        bankAccountId: new InputValue('', false),
    });
    const style = {
        marginTop: '10px',
    };

    const sendRequest = async () => {
        let reqValues: any = {
            loanSum: values.loanSum.getValue(),
            paymentType: getPaymentTypeFromOption(values.paymentType.getValue()),
            project: { id: projectData[0].id },
        };
        if (values.bankAccountId.getValue() !== '' && values.bankAccountId.getInfo() === 'SELECTED') {
            reqValues = { ...reqValues, bankAccount: { id: values.bankAccountId.getValue() } };
        }
        await DataService.performAsyncPostRequest(`/loan/application`, navigate, memberData.type === 'beg_Contacts' ?
            { contact: { id: memberData.id }, ...reqValues } : { account: { id: memberData.id }, ...reqValues });
    };

    const changeBankAccountTypesRequest = (bankAccount: Bank_Account) => {
        setBtnIsLoading(true);
        let bacctypes = bankAccount.bankAccountTypes;
        if (!bacctypes.includes('LOAN_COLLECT')) bacctypes.push('LOAN_COLLECT');
        if (!bacctypes.includes('LOAN_INTEREST')) bacctypes.push('LOAN_INTEREST');
        if (!bacctypes.includes('LOAN_REPAYMENTS')) bacctypes.push('LOAN_REPAYMENTS');
        DataService.performAsyncPutRequest(`/bankaccount/bankaccounttype`, navigate,
            {
                bankAcountId: bankAccount.id,
                bankAccountTypes: bacctypes.filter((s) => !EnumToStringService.getBankAccountType(s).startsWith('Darlehen ')),
            },
        ).then(() => {
            updateBankAccounts();
            setBtnIsLoading(false);
        });
    };

    //temporary function until different approches to paymentoption are unified
    const getPaymentTypeFromOption = (value: string) => {
        if (value === 'sepa') return 'DEBT_ORDER';
        else if (value === 'seperate') return 'BANK_TRANSFER';
        else return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = values['loanSum'].getValue();
        values['loanSum'].setValue(e.target.value);
        const newValue = parseInt(e.target.value);
        if (!validationService.validateFunction(values, 'loanSum', 'positiveFloat', false, false, props.config)) {
            values['loanSum'].setValue(valueBefore);
        }
        if (newValue % 1000 !== 0) {
            values['loanSum'].setError('Darlehensbetrag muss in 1000er Schritten angegeben werden!');
        }

        reload(!reloadVar);
    };

    if (!finishedLoading.nLoanMember || !finishedLoading.nLoanProject || !finishedLoading.paymentOptions || !finishedLoading.nLoanBankAccounts) {
        return <Loading />;
    }

    return (
        <Box display='flex' justifyContent='center'>
            <div className='tableDiv' style={{ width: props.isMobile ? '100%' : '50%', display: 'inline-block' }}>
                <Typography variant='h6'>Zeichnungsabsicht</Typography>
                <Typography variant='body2'>Für Projekt {projectData[0].name}</Typography>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                    >
                        {!isOrganisation() ? 'Persönliche Angaben' : 'Kontaktdaten'}
                    </AccordionSummary>
                    <AccordionDetails>
                        {!isOrganisation() ? <><FormControl style={style} fullWidth>
                            <InputLabel size='small'>Anrede *</InputLabel>
                            <Select
                                value={memberData.salutation}
                                label='Anrede'
                                name='salutation'
                                variant='outlined'
                                size='small'
                                disabled
                            >
                                <MenuItem value={'Herr'}>Herr</MenuItem>
                                <MenuItem value={'Frau'}>Frau</MenuItem>
                                <MenuItem value={'Keine Anrede'}>Keine Anrede</MenuItem>
                            </Select>
                        </FormControl>
                            <TextField style={style} fullWidth value={memberData.title} name='title' label='Titel'
                                       variant='outlined' size='small' disabled />
                            <TextField style={style} fullWidth value={memberData.firstname} name='firstname'
                                       label='Vorname' variant='outlined' size='small' required disabled />
                            <TextField style={style} fullWidth value={memberData.lastname} name='lastname'
                                       label='Nachname' variant='outlined' size='small' required disabled />
                        </> : <>
                            <TextField style={style} fullWidth value={memberData.name} name='name' label='Name'
                                       variant='outlined' size='small' required disabled />
                        </>}

                        <TextField style={style} fullWidth value={memberData.address_street} name='addressStreet'
                                   label='Straße, Hausnummer' variant='outlined' size='small' required disabled />
                        <TextField style={style} fullWidth value={memberData.address_extra} name='addressExtra'
                                   label='Addresszusatz (optional)' variant='outlined' size='small' disabled />
                        <TextField style={style} fullWidth value={memberData.address_postalcode}
                                   name='addressPostalcode'
                                   label='Postleitzahl' variant='outlined' size='small' required disabled />
                        <TextField style={style} fullWidth value={memberData.address_city} name='addressCity'
                                   label='Ort'
                                   variant='outlined' size='small' required disabled />
                        <TextField style={style} fullWidth value={memberData.primaryEmail} name='email' type='email'
                                   label='E-Mail' variant='outlined' size='small' required disabled />
                    </AccordionDetails>
                </Accordion>


                <Typography variant='subtitle1' style={style}>Darlehen</Typography>
                <TextField type='number' fullWidth style={style} value={values.loanSum.getValue()}
                           onChange={handleChange} error={values.loanSum.getError()} name='loanSum'
                           label='Betrag des Darlehens' variant='outlined' size='small' required InputProps={{
                    inputProps: {
                        min: props.config.loanMinSum,
                        step: 1000,
                    },
                }} />
                {values.loanSum.getError() ? (<FormHelperText error={true}>
                    Der Darlehensbetrag darf nur in 1000er Schritten gewählt werden!
                </FormHelperText>) : ''}
                <FormControl style={style} fullWidth>
                    <FormLabel>Zahlungsoption *</FormLabel>
                    <RadioGroup
                        value={values.paymentType.getValue()}
                        onChange={e => {
                            values.paymentType.setValue(e.target.value);
                            reload(!reloadVar);
                        }}
                        name='paymentOption'
                    >
                        {paymentOptions.map(e => e.activated &&
                            <FormControlLabel key={e.id} value={e.code} control={<Radio />} label={e.name} />)}
                    </RadioGroup>
                </FormControl>
                <div style={values.paymentType.getValue() !== 'sepa' ? { display: 'none' } : {}}>
                    <Typography variant='subtitle1'>Verwendetes Konto</Typography>
                    {bankData.length === 0 ? <>
                        <Typography variant='body2'>Aktuell ist kein Konto hinterlegt.</Typography>
                    </> : <Typography variant='caption'>Sie können entweder ein Konto für generell alle
                        Darlehenstransaktionen auswählen oder ein Konto speziell diesem potenziellen Darlehen zuordnen.
                        Unter "Persönliche Daten" können Sie die Verwendungszwecke noch genauer
                        einstellen.</Typography>}

                    <List sx={{
                        ...style,
                        p: 0,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider',
                        backgroundColor: 'background.paper',
                    }} aria-label='mailbox folders'>
                        {bankData.map(bankacc => {
                            const isChoosen = (values.bankAccountId.getValue() === bankacc.id);
                            return <>
                                <ListItem style={isChoosen ? { border: '1px solid var(--c1)' } : {}}>
                                    <ListItemText primary={`${bankacc.iban}, ${bankacc.bank.name}`} secondary={
                                        isChoosen ? <></> : <><Button size='small' style={{ marginRight: 10 }}
                                                                      onClick={() => {
                                                                          values.bankAccountId.setValue(bankacc.id);
                                                                          values.bankAccountId.setInfo('SELECTED');
                                                                          reload(!reloadVar);
                                                                      }}>Speziell Auswählen für dieses Darlehen</Button>
                                            <Button size='small' onClick={() => changeBankAccountTypesRequest(bankacc)}>Dieses
                                                Konto standardmäßig für Darlehen verwenden</Button></>
                                    } />
                                </ListItem>
                                <Divider component='li' />
                            </>;
                        })}
                        <ListItem>
                            <ListItemText primary={``} secondary={
                                <><Button variant='contained' size='small'
                                          onClick={() => navigate(`/bankaccount/create`)}>Neues Konto
                                    hinzufügen</Button></>
                            } />
                        </ListItem>

                    </List>

                </div>
                <div style={{ textAlign: 'right' }}>
                    <LoadingButton loading={btnIsLoading} variant='contained' disableElevation
                                   sx={{ marginTop: '10px' }} onClick={async () => {
                        setBtnIsLoading(true);
                        let isError = false;
                        if (!validationService.validateFunction(values, 'loanSum', 'positiveFloat', false, false, props.config)) isError = true;
                        if (values.paymentType.getValue() === '') {
                            isError = true;
                        }
                        if (isError) {
                            reload(!reloadVar);
                            setBtnIsLoading(false);
                            return;
                        }
                        await sendRequest();
                        setBtnIsLoading(false);
                        navigate('/projectloans');
                    }}>Zeichnungsabsicht abschicken</LoadingButton>
                </div>
            </div>
        </Box>
    );

};
