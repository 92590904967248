import axios from 'axios';
import { ProjectDto, TaskDto } from '../types';
import AuthService from "./AuthService";

class LoanService {
    static async getProjectForLoan(loanId: string): Promise<ProjectDto>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.beg-in.de}/loan/${loanId}/project`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }
}

export default LoanService;

