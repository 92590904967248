

const getBankAccountType = (type: string) => {
    if (type === "DEFAULT") return "Standardkonto"
    else if (type === "SHARE_INTEREST") return "Zinsen von Anteilen"
    else if (type === "SHARE_COLLECT") return "Einzug von Anteilen"
    else if (type === "LOAN_INTEREST") return "Zinsen von Darlehen"
    else if (type === "LOAN_COLLECT") return "Einzug von Darlehen"
    else if (type === "LOAN_REPAYMENTS") return "Darlehenstilgungen"
    else return "Darlehen " + type
}

const EnumToStringService = {
    getBankAccountType,
};

export default EnumToStringService;