import Keycloak from 'keycloak-js';
import keycloakConfig from './keycloak'

const _kc = new Keycloak(keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = () => {

    return _kc.init({
        onLoad: 'check-sso',
        //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
    })
};


const doLogin = _kc.login;

const doLogout = _kc.logout;

const doAccountManagement = _kc.accountManagement;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));

const AuthService = {
    initKeycloak,
    doLogin,
    doLogout,
    doAccountManagement,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
};

export default AuthService;