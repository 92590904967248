import { Link } from '@mui/material';
import styles from '../styles/Footer.module.css';
import { useEffect } from 'react';
import { useState } from 'react';

interface FooterProps {
    config: Config
}

export const Footer: React.FC<FooterProps> = (props) => {
    let [version, setVersion] = useState<String>('');
    useEffect(() => {
        fetch('/info.json').then((response) => response.json()).then((response) => setVersion(response.version));
    }, [])
    return (
        <div className={styles.footerDiv}>
            <p className={styles.footerLink}>{props.config.cooperativeName}</p>
            <Link className={styles.footerLink} onClick={() => window.open(`${props.config.legalNoticeUrl}`, '_blank')}>Impressum</Link>
            &nbsp;-&nbsp;
            <Link className={styles.footerLink} onClick={() => window.open(`${props.config.dataProtectionRegulationUrl}`, '_blank')}>Datenschutzerklärung</Link>
            <p>Version {version}</p>
        </div>
    )
}
