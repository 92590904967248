import { Button, Link, Typography } from "@mui/material"
import { HeaderTable } from "../../components/HeaderTable"
import { useEffect, useState } from "react"
import DataService from "../../services/DataService"
import { useNavigate } from "react-router-dom"
import EmptyTypesService from "../../services/EmptyTypesService"
import { Loading } from "../../components/Loading"


interface ProjectsProps {
    isMobile: boolean
    config: Config
    inPortal: boolean
}

export const ProjectsIframe: React.FC<ProjectsProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ projects: boolean }>({ projects: false });

    const [isAsking, setIsAsking] = useState<boolean>(false);

    const [projectData, setProjectData] = useState<Project[]>([EmptyTypesService.emptyProject()]);

    useEffect(() => {
        DataService.performAnonymousRequest(`/projects${props.inPortal ? '' : '?state=FINANCING'}`, finishedLoading, setFinishedLoading, setProjectData, "projects", navigate);
    }, []);


    const to2dProjectsArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl: Project) => {
            returnElement.push([
                <Link onClick={() => window.open(`${objectEl.website}`, '_blank')} fontSize={props.inPortal ? 16 : 14}>{objectEl.name}</Link>,
                <p style={{fontSize: props.inPortal ? "16px" : "12px", margin: props.inPortal ? "8px 0px" : "12px 0px" }}>{getProjectState(objectEl.state)}</p>,
                <Button variant="contained" size="small" disabled={objectEl.state !== "FINANCING" ? true : false} onClick={() => {
                    if (!props.inPortal) {
                        setIsAsking(true)
                    } else {
                        navigate(`/projectloans/new/${objectEl.id}`);
                    }
                }}>Jetzt beteiligen</Button>
            ]);
        })
        return returnElement;
    }

    const getProjectState = (state: string) => {
        if (state === "CANCELLED") return "Eingestellt"
        else if (state === "CONSTRUCTION") return "Im Bau"
        else if (state === "FINANCING") return "Finanzierungsphase"
        else if (state === "OPERATIONAL") return "In Betrieb"
        else if (state === "PLANNING") return "In Planung"
        else return "Unbekannt"
    }
    
    if (!finishedLoading.projects) {
        return <Loading />
    }

    if (isAsking) {
        return (
            <div>
                <Typography variant="body1" textAlign={"center"} paddingTop={"8px"}>Sind Sie bereits Mitglied der {props.config.cooperativeName}?</Typography>
                <div style={{textAlign: "center"}}>
                    <Button variant="contained" style={{margin: "5px"}} onClick={() => {window.open(window.location.origin + "/projectloans/new/none",'_blank');}}>Ja</Button>
                    <Button variant="outlined" style={{margin: "5px"}} onClick={() => {window.open(window.location.origin + "/mitgliedsantrag",'_blank');}}>Nein</Button>
                </div>
                <div style={{textAlign: "center"}}>
                    <Button style={{margin: "5px"}} onClick={() => {setIsAsking(false)}}>Zurück</Button>
                </div>
                
            </div>
        )
    }
    return (
        <div>
                <HeaderTable headerRows={["Name", "Status",""]}
                    rows={
                        to2dProjectsArray(projectData)
                    } isMobile={props.isMobile}
                />
        </div>
    )
}
