import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import AuthService from "../services/AuthService"
import styles from '../styles/Table.module.css';


interface TitleTableProps {
    title: string,
    rows: any
}

/*function createData(
    name: string,
    value: string
) {
    return { name, value };
}

const rows2 = [
    createData('Name', "Nico"),
];*/

//Can be replaced with Material UI table

export const TitleTable: React.FC<TitleTableProps> = (props) => {
    let colorChange = false;
    return (
        <table className={styles.titletable}><tbody>
            <tr>
                <th colSpan={2} className={styles.titletableTitle}>{props.title}</th>
            </tr>
            {props.rows.map((row: any) => {
                colorChange = !colorChange;
            return <tr key={row.name}>
                    <td className={styles.titletableCell} align="right" style={{backgroundColor: colorChange ? "#F8F8F8" : "#eaf4e5"}}>{row.name}</td>
                    <td className={styles.titletableCell} style={{backgroundColor: colorChange ? "#F8F8F8" : "#eaf4e5"}}>{row.value}</td>
                </tr>
            })}
        </tbody></table>
    )
}
