import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import DataService from '../../services/DataService';
import MemberService from '../../services/MemberService';
import { Loading } from '../../components/Loading';
import { Box, Typography } from '@mui/material';
import TaskService from '../../services/TaskService';
import { TaskDto } from '../../types';
import { SignLoanContractTask } from './taskTemplates/SignLoanContractTask';
import { CheckChangesTask } from './taskTemplates/CheckChangesTask';

interface TaskProps {
    isMobile: boolean
    config: Config
}

declare global {
}

export const TaskPage: React.FC<TaskProps> = (props) => {
    const { id } = useParams();
    const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
    const [task, setTask] = useState<TaskDto>();

    useEffect(() => {
        const loadTask = async (taskId: string) => {
            const taskDto = await TaskService.getTaskById(taskId);
            setTask(taskDto);
            setFinishedLoading(true);
        }
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            if(id) loadTask(id);
        }
    }, []);
    

    if (!finishedLoading) {
        return <Loading />
    }
    
    switch(task?.taskDefinitionKey){
        case "sign_loan_contract": return (<SignLoanContractTask task={task} isMobile={props.isMobile}></SignLoanContractTask>)
        case "check_changes": return (<CheckChangesTask task={task} isMobile={props.isMobile}></CheckChangesTask>)
    }

    return (
        <Box display="flex" alignItems="center">
             <Typography variant="h6">Task</Typography>
             {JSON.stringify(task)}
        </Box>
    )

}