import axios from "axios";
import { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import { Loading } from "../components/Loading";
import { HeaderTable } from "../components/HeaderTable";
import { Box, Typography } from '@mui/material';
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";
import MemberService from "../services/MemberService";


interface MemberAccountProps {
    isMobile: boolean
    config: Config
}

declare global {
    type Transaction = {
        name: string,
        value: number,
        dateBooked: string,
        dateValuta: string,
        paymentType: string,
        transactionType: string
    }
}

export const MemberAccount: React.FC<MemberAccountProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{transactions: boolean}>({transactions: false});

    const [transactionData, setTransactionData] = useState<Transaction[]>([{
        name: "Nicht vorhanden",
        value: 0.00,
        dateBooked: "Nicht vorhanden",
        dateValuta: "Nicht vorhanden",
        paymentType: "Nicht bekannt",
        transactionType: "Nicht bekannt"
    }]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalTransactionService/getTransactions${MemberService.getUrlAddition()}`,finishedLoading,setFinishedLoading,setTransactionData,"transactions",navigate)
        }
    }, []);
    

    if (!finishedLoading.transactions) {
        return <Loading />
    }

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl:any) => {
            returnElement.push([
                objectEl.name,
                objectEl.value < 0 ? -1*objectEl.value.toLocaleString(undefined,{minimumFractionDigits: 2}) + " €" : "",
                objectEl.value > 0 ? objectEl.value.toLocaleString(undefined,{minimumFractionDigits: 2}) + " €" : "",
                objectEl.dateBooked ? new Date(objectEl.dateBooked).toLocaleDateString() : "",
                objectEl.dateValuta ? new Date(objectEl.dateValuta).toLocaleDateString() : "",
                getPaymentType(objectEl.paymentType),
                getTransactionType(objectEl.transactionType)
             ]);
        })
        return returnElement;
    }

    const getTransactionType = (type: string) => {
        if (type === "CHURCH_TAX") return "Kirchensteuer"
        else if (type === "CONTANGO") return "Aufgeld"
        else if (type === "CONTRA_ENTRY") return "Gegenbuchung"
        else if (type === "DIVIDEND") return "Dividende"
        else if (type === "INTEREST") return "Zinsen"
        else if (type === "LOAN") return "Darlehen"
        else if (type === "PAYMENT") return "Auszahlung"
        else if (type === "REPAYMENT") return "Tilgung"
        else if (type === "SHARE") return "Anteil"
        else if (type === "SOLI_TAX") return "Solidaritätszuschlag"
        else if (type === "TAX") return "Kapitalertragssteuer"
        else return "Unbekannt"
    }
    const getPaymentType = (type: string) => {
        if (type === "BANK_TRANSFER") return "Überweisung"
        else if (type === "DEBT_ORDER") return "Einzugsermächtigung"
        else if (type === "INTERNAL") return "Umbuchung"
        else if (type === "PAYPAL") return "Paypal"
        else if (type === "TAX") return "Steuerabführung"
        else return "Unbekannt"
    }
    
    return (
        <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            padding={2}
        ><Box
            className='tableDiv'
            width={props.isMobile ? '100%' : '1024px'}
            display='inline-block'
        >
            <Typography variant="h6">Mitgliedskonto</Typography>
            <HeaderTable headerRows={["Name","Auszahlung","Einzahlung","Buchung am","Wertstellung am","Zahlungsart","Transaktionstyp"]}
            rows={
                to2dArray(transactionData)
            } isMobile={props.isMobile}
            />
        </Box>
        </Box>
    )


}