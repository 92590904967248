import { Button, Typography } from "@mui/material"
import { useParams } from 'react-router-dom';

interface ServiceUnavailableErrorProps {
    isMobile: boolean
}


export const ServiceUnavailableError: React.FC<ServiceUnavailableErrorProps> = (props) => {
    const { path } = useParams();
    return (
        <div style={{
            padding: props.isMobile ? 10 : 20,
            margin: props.isMobile ? "10px 8px" : "20px 25%",
            marginBottom: props.isMobile ? 80 : 20,
            width: props.isMobile ? "calc(100% - 16px - 20px)" : "calc(50% - 40px)"
        }}>
            <Typography variant="h4" sx={{ marginBottom: "5px", display: "inline-block" }}>Mitgliederportal</Typography>
            <Typography variant="body1">Leider ist unser Server zur Zeit nicht erreichbar. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
            Sollte der Fehler auch nach längerer Zeit nicht behoben sein, kontaktieren Sie uns bitte.</Typography>
            <Typography variant="body2">Fehler 503</Typography>
            <Button variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                window.location.pathname = decodeURIComponent(path as string);
            }}>Erneut versuchen</Button>
        </div>
    )
}
