import { Box, CircularProgress, Typography } from '@mui/material';


export const Loading = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <CircularProgress size={60} thickness={5} />
            <Typography variant="h6" sx={{ mt: 2 }}>
                Lade Daten...
            </Typography>
        </Box>
    )
}
